<template>
  <div class="container">
    <div class="call-box">
      <img
        class="tel-icon"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/uLifeRed/tel.png"
      />
      <div class="tel-num">
        <div class="tit">客服热线</div>
        <div class="tel" @click="handleContact">
          <a id="call">400-067-5108</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$nextTick(()=>{
      this.handleContact();
    })
  },
  methods: {
    handleContact() {
      const call = document.getElementById("call");
      call.setAttribute("href", "tel:4000675108");
      call.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .call-box {
    box-shadow: 10px 20px 40px rgb(215, 213, 213);
    display: flex;
    align-items: center;
    padding: 15px 45px;

    .tel-icon {
      width: 104px;
      height: 104px;
    }

    .tel-num {
      margin-top: -12px;
      padding-left: 30px;
      .tit {
        font-size: 23px;
        font-weight: 500;
        color: #333333;
      }

      .tel {
        font-weight: 600;
        font-size: 30px;
        color: #5a6af6;
        text-decoration: underline;
      }
    }
  }
}
</style>